import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { Row, Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-tooltip/dist/react-tooltip.css';

import API from 'components/api';
import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import CheckboxList from 'components/controls/checkbox_list';
import DateInput from 'components/controls/date';
import PhoneControl from 'components/controls/phone';
import Select from 'components/controls/select';
import Textbox from 'components/controls/textbox';
import RadioList from 'components/controls/radio_list';
import ZipInput from 'components/controls/zip';
import { cloneDeep, optsVisa, formatMonth, equalExcept } from 'components/modules/_misc';
import { calculateReciprocityStatus } from 'components/modules/app';
import { months_passed, formatDateTime } from 'components/modules/date';
import { legal_genders, states, levels_of_study, opts_visa_types } from 'components/modules/opts';
import { colleges, getAvailableTerms, RECIPROCITY, HIGHER_ED, blockers } from 'components/modules/reciprocity';
import { IS_VALID } from 'components/modules/validation';
import { GlobalActions } from 'reducers/global';
import { UserActions } from 'reducers/user';

const empty_date = '0000-00-00 00:00:00';

class Reciprocity extends Component {
	constructor(props) {
		super(props);

		const { reciprocity } = props,
			app_ids = Object.keys(reciprocity),
			submitted_apps_exist = app_ids.some(i => (reciprocity[i].submit_date || empty_date) !== empty_date);

		this.optTerms = getAvailableTerms();

		this.state = {
			available_terms: [],
			current_id: app_ids.length ? app_ids[0] : -1,
			reciprocity: cloneDeep(RECIPROCITY),
			blockers: [],
			higher_ed_rec: cloneDeep(HIGHER_ED),
			idx_higher_ed: -1,
			submitted: false,
			ssn_confirm: '',
			show_saved: false,
			show_info_modal: false,
			show_campuses_modal: false
		};

		if (submitted_apps_exist) this.state.current_id = 0;
	}

	componentDidMount = () => {
		const { current_id } = this.state;
		this.onChangeCurrentId(current_id);

		this._mounted = true;
	};

	componentDidUpdate = (prevProps, prevState) => {
		const { visa_types } = this.props,
			{ current_id, submitted, ssn_confirm } = this.state,
			reciprocity = cloneDeep(this.state.reciprocity, RECIPROCITY);

		if (current_id !== prevState.current_id) this.onChangeCurrentId(current_id);

		if (!submitted && !!reciprocity.user_id && !equalExcept(reciprocity, prevState.reciprocity, ['id', 'status'])) {
			const _blockers = blockers(reciprocity, ssn_confirm, visa_types);
			reciprocity.status = _blockers.length ? 'WIP' : calculateReciprocityStatus(reciprocity);

			this.setState({ reciprocity: reciprocity, blockers: _blockers });
		}
	};

	componentWillUnmount = () => {
		this._mounted = false;
	};

	getAPI = () => {
		const { support_student_id, campus_admin } = this.props;
		return new API('', support_student_id, campus_admin);
	};

	prefillHighSchoolData = (_apps, recip) => {
		const app_with_grad_hs = _apps.find(_app =>
			_app.json_obj.academic_background.highschools.some(hs => hs.did_graduate === 'Yes')
		);

		if (app_with_grad_hs) {
			const grad_hs = app_with_grad_hs.json_obj.academic_background.highschools.find(
					hs => hs.did_graduate === 'Yes'
				),
				{ graduation_date } = grad_hs,
				{ field_city, field_state, title } = grad_hs.selected_highschool;

			recip.hs_name = title;
			recip.hs_city = field_city;
			recip.hs_state = field_state;
			recip.hs_grad_date = graduation_date;
		}
	};

	prefillParentAddressData = (_apps, recip) => {
		const app_with_parent = _apps.find(_app =>
			_app.json_obj.parent_info.parents.find(parent => parent.relationship_to_applicant === recip.relationship)
		);

		if (app_with_parent) {
			let parent = app_with_parent.json_obj.parent_info.parents.find(
					parent => parent.relationship_to_applicant === recip.relationship
				),
				addr =
					parent.same_current_address === 'Yes'
						? app_with_parent.json_obj.contact_information.permanent_address
						: parent.current_address,
				{ address_1, address_2, city, state, zip } = addr,
				street_addr = '';

			street_addr += address_1;
			if (!street_addr && address_2) street_addr += `, ${address_2}`;

			recip.relationship_addr_street = street_addr;
			recip.relationship_addr_city = city;
			recip.relationship_addr_state = state;
			recip.relationship_addr_zip = zip;
		}
	};

	prefillData = recip => {
		const { apps, user_id } = this.props,
			prefills = {
				ssn: app => {
					const { social_security_number: _ssn } = app.json_obj.personal_information.social_security_number;
					return IS_VALID.ssn(_ssn) ? _ssn : '';
				},
				dob: app => app.json_obj.personal_information.date_of_birth,
				gender: app => app.json_obj.personal_information.gender,
				us_citizen: app => {
					const { citizen_country_code } = app.json_obj.personal_information;
					if (citizen_country_code) return citizen_country_code === 'USA' ? 'Yes' : 'No';
				},
				visa_type: app => app.json_obj.initial_information.visa_type,
				addr_street: app => {
					let { address_1, address_2 } = app.json_obj.contact_information.permanent_address,
						str = '';

					str += address_1;
					if (!str && address_2) str += `, ${address_2}`;

					return str;
				},
				phone: app => app.json_obj.contact_information.preferred_phone_number || app.phone_number,
				addr_city: app => app.json_obj.contact_information.permanent_address.city,
				addr_state: app => app.json_obj.contact_information.permanent_address.state,
				addr_zip: app => app.json_obj.contact_information.permanent_address.zip,
				provide_parent_info: app => app.json_obj.residency.provide_parent_info,
				relationship: app => app.json_obj.residency.residency_relationship,
				relationship_us_citizen: app => app.json_obj.residency.relationship_us_citizen,
				relationship_visa_type: app => app.json_obj.residency.relationship_visa_type,
				relationship_visa_type_other: app => app.json_obj.residency.relationship_visa_type_other,
				relationship_lived_wi_12_months: app => app.json_obj.residency.relationship_lived_wi_12_months,
				relationship_employed: app => app.json_obj.residency.relationship_employed,
				relationship_income_taxes: app => app.json_obj.residency.relationship_income_taxes,
				relationship_registered_vote: app => app.json_obj.residency.relationship_registered_vote,
				relationship_valid_license: app => app.json_obj.residency.relationship_valid_license,
				self_live_in_wi: app => app.json_obj.residency.self_live_in_wi,
				self_lived_wi_date: app => app.json_obj.residency.self_lived_wi_date,
				self_lived_wi_12_months: app => app.json_obj.residency.self_lived_wi_12_months,
				self_employed: app => app.json_obj.residency.self_employed,
				self_income_taxes: app => app.json_obj.residency.self_income_taxes,
				self_registered_vote: app => app.json_obj.residency.self_registered_vote,
				self_valid_license: app => app.json_obj.residency.self_valid_license,
				self_claim_dependent: app => app.json_obj.residency.self_claim_dependent,
				self_he_enrolled: app => app.json_obj.residency.self_he_enrolled
			};

		if (!recip.campuses.length) {
			let _apps = Object.keys(apps)
				.map(_app_id => apps[_app_id])
				.sort((a, b) => {
					if (a.date_submitted && !b.date_submitted) return -1;
					if (!a.date_submitted && b.date_submitted) return 1;

					return 0;
				});

			Object.keys(prefills).forEach(key => {
				const arr = _apps.map(prefills[key]).filter(val => !!val);
				if (arr.length) recip[key] = arr[0];
			});

			this.prefillHighSchoolData(_apps, recip);
			if (recip.relationship) this.prefillParentAddressData(_apps, recip);
		}

		recip.user_id = user_id;

		return recip;
	};

	higherEdDateIsValid = str => {
		let { higher_ed_rec } = this.state,
			val = higher_ed_rec[str];

		if (val.length !== 7) return false;

		const months = months_passed(val);

		if (str === 'attended_to') {
			const from_months = months_passed(higher_ed_rec.attended_from);
			if (months > from_months) return false;
		}

		return months >= -4 * 12 && months <= 82 * 12;
	};

	canSave = () => {
		let { reciprocity } = this.state,
			{ campuses, term_name, term_year } = reciprocity;

		if (equalExcept(reciprocity, this.saved_recip, ['id', 'status'])) return false;

		return !!term_name && !isNaN(term_year) && term_year > 0 && !!campuses.length;
	};

	onChange = (prop, val) => {
		let { visa_types } = this.props,
			reciprocity = cloneDeep(this.state.reciprocity);

		if (prop === 'term') {
			reciprocity.term_name = val ? val.split(' ')[0] : '';
			reciprocity.term_year = val ? Number(val.split(' ')[1]) : -1;
		} else {
			reciprocity[prop] = val;
		}

		const { us_citizen, self_live_in_wi, relationship_us_citizen, relationship_visa_type, provide_parent_info } =
			reciprocity;

		if (us_citizen !== 'No') reciprocity.visa_type = '';

		const selectedVisaType = visa_types.find(opt => opt.id === reciprocity.visa_type);

		if (selectedVisaType?.title !== 'Other') reciprocity.visa_type_other = '';

		if (self_live_in_wi !== "Yes, but I haven't always") reciprocity.self_lived_wi_date = '';

		if (relationship_us_citizen !== 'No') reciprocity.relationship_visa_type = '';

		if (relationship_visa_type !== 'Other') reciprocity.relationship_visa_type_other = '';

		if (provide_parent_info === 'No') {
			reciprocity.relationship = '';
			reciprocity.relationship_addr_street = '';
			reciprocity.relationship_addr_city = '';
			reciprocity.relationship_addr_state = '';
			reciprocity.relationship_addr_zip = '';
			reciprocity.relationship_us_citizen = '';
			reciprocity.relationship_visa_type = '';
			reciprocity.relationship_visa_type_other = '';
			reciprocity.relationship_lived_wi_12_months = '';
			reciprocity.relationship_employed = '';
			reciprocity.relationship_income_taxes = '';
			reciprocity.relationship_registered_vote = '';
			reciprocity.relationship_valid_license = '';
		}

		this.setState({ reciprocity: reciprocity });
	};

	onChangeCurrentId = i => {
		const { reciprocity, visa_types } = this.props,
			submitted_terms = Object.keys(reciprocity)
				.filter(j => (reciprocity[j].submit_date || empty_date) !== empty_date)
				.map(j => `${reciprocity[j].term_name} ${reciprocity[j].term_year}`);

		if (!i) {
			this.setState({
				available_terms: this.optTerms.filter(opt => !submitted_terms.includes(opt)),
				current_id: i,
				reciprocity: {},
				blockers: [],
				submitted: false,
				ssn_confirm: '',
				show_saved: false,
				show_info_modal: false,
				show_campuses_modal: false
			});

			this.saved_recip = {};
		} else if (i > 0) {
			const recip = cloneDeep(reciprocity[i], RECIPROCITY),
				current_term = `${recip.term_name} ${recip.term_year}`;

			recip.status = blockers(recip, recip.ssn || '', visa_types).length
				? 'WIP'
				: calculateReciprocityStatus(recip);

			this.setState({
				available_terms: this.optTerms.filter(opt => opt === current_term || !submitted_terms.includes(opt)),
				current_id: i,
				reciprocity: recip,
				submitted: recip.submit_date !== empty_date,
				ssn_confirm: recip.ssn || ''
			});

			this.saved_recip = cloneDeep(recip);
		} else {
			let recip = cloneDeep(RECIPROCITY);
			this.prefillData(recip);

			recip.status = 'WIP';

			this.setState({
				available_terms: this.optTerms.filter(opt => !submitted_terms.includes(opt)),
				current_id: i,
				reciprocity: recip,
				ssn_confirm: recip.ssn || ''
			});

			this.saved_recip = {};
		}
	};

	onChangeHigherEd = (prop, val) => {
		let higher_ed_rec = cloneDeep(this.state.higher_ed_rec);

		higher_ed_rec[prop] = val;
		this.setState({ higher_ed_rec: higher_ed_rec });
	};

	onSave = e => {
		const { updateReciprocity, captureError } = this.props,
			{ submitted } = this.state,
			reciprocity = cloneDeep(this.state.reciprocity);

		e.preventDefault();
		e.stopPropagation();

		this.getAPI()
			.saveReciprocity(reciprocity)
			.then(resp => {
				if (resp.result !== 'success') throw new Error(resp.result);

				if (resp.recip_id) {
					reciprocity.id = resp.recip_id;
					this.setState({ reciprocity: reciprocity });
				}

				updateReciprocity(reciprocity);
				this.saved_recip = reciprocity;
			})
			.catch(ex => captureError(ex));

		if (submitted) {
			this.onChangeCurrentId(0);
		} else {
			this.setState({ show_saved: true });

			setTimeout(() => {
				this.setState({ show_saved: false });
			}, 3000);
		}
	};

	onSubmit = e => {
		const { updateReciprocity, captureError } = this.props,
			{ reciprocity, submitted } = this.state,
			strTerm = `${reciprocity.term_name} ${reciprocity.term_year}`,
			confirmWindow = onClose => {
				return submitted ? (
					<>
						<h1>Add Campuses</h1>
						<p>This will add the following campuses to your existing {strTerm} reciprocity application:</p>
						<ul>
							{reciprocity.campuses
								.filter(c => !this.saved_recip.campuses.includes(c))
								.map(str => (
									<li key={str}>{str}</li>
								))}
						</ul>
						<p>Are you sure you want to submit?</p>
						<div className='react-confirm-alert-button-group'>
							<button
								onClick={() => {
									this.onSave(e);
									onClose();
								}}>
								Yes, Submit
							</button>
							<button onClick={onClose}>Cancel</button>
						</div>
					</>
				) : (
					<>
						<h1>Submit Reciprocity Application</h1>
						<p>
							Please confirm that your reciprocity application is accurate. Once submitted, you will be
							able to add additional Minnesota campuses, but you will not be able to make any other
							changes.
						</p>
						<p>Are you sure you want to submit?</p>
						<div className='react-confirm-alert-button-group'>
							<button
								onClick={() => {
									this.getAPI()
										.submitReciprocity(reciprocity)
										.then(resp => {
											if (resp.result !== 'success') throw new Error(resp.err_reason);

											const _reciprocity = cloneDeep(reciprocity);
											_reciprocity.submit_date = resp.row.submit_date;

											updateReciprocity(_reciprocity);

											if (this._mounted) this.onChangeCurrentId(0);
										})
										.catch(ex => captureError(ex));

									onClose();
								}}>
								Yes, Submit
							</button>
							<button onClick={onClose}>Cancel</button>
						</div>
					</>
				);
			};

		e.preventDefault();
		e.stopPropagation();

		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className='react-confirm-alert'>
						<div className='react-confirm-alert-body'>{confirmWindow(onClose)}</div>
					</div>
				);
			}
		});
	};

	renderInfoModal = () => {
		const { show_info_modal } = this.state;

		return (
			<Modal show={show_info_modal}>
				<Modal.Header>
					<Modal.Title className='h2'>
						MN-WI Tuition Reciprocity Application Instructions
						<a onClick={() => this.setState({ show_info_modal: false })} className='close-link-top'>
							Close
						</a>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className='recip-faq'>
					<h3>Deadline</h3>
					<p>
						The application deadline is the last day of classes at the institution you are or will be
						attending for the term benefits are needed. Applications <strong>will not</strong> be processed
						retroactively. If you wish to participate in the program for the entire academic year, your
						application must be correctly completed and submitted by the last day of scheduled classes in
						the fall term at the institution you are or will be attending.
					</p>

					<h3>Who is Eligible</h3>
					<p>
						Wisconsin residents attending Minnesota public institutions are eligible to apply for
						reciprocity benefits. Professional students enrolling in a Doctor of Medicine, Doctor of Dental
						Sciences, or Doctor of Veterinary Medicine program in the public institutions are ineligible for
						reciprocity tuition.
					</p>

					<h3>Notification of Acceptance</h3>
					<p>
						You will receive the results of your application within four to six weeks after you have
						applied. If you do not receive results within six weeks, you should assume your application was
						not properly submitted and apply again.
					</p>

					<h3>Application for Admission</h3>
					<p>
						Application to the Minnesota-Wisconsin Reciprocity Program does not constitute application for
						admission to an educational institution. Regardless of your eligibility for tuition reciprocity,
						you must still apply and qualify for admission to the school of your choice, following the
						procedures required by that institution.
					</p>

					<h3>Administrative Agencies</h3>
					<p>
						The Universities of Wisconsin will determine the residency and eligibility status of Wisconsin
						applicants enrolled in Minnesota public institutions and will certify to the Minnesota public
						institutions which students are eligible to pay the established reciprocity fee.
					</p>

					<h3>Eligible Institutions</h3>
					<section>
						<h4>University of Minnesota</h4>
						<ul>
							{colleges.mn.map((name, i) => (
								<li key={i}>{name}</li>
							))}
						</ul>

						<h4>Minnesota State Universities</h4>
						<ul>
							{colleges.mn_state.map((name, i) => (
								<li key={i}>{name}</li>
							))}
						</ul>
					</section>
					<section>
						<h4>Minnesota State Colleges</h4>
						<ul>
							{colleges.mn_state_college.map((name, i) => (
								<li key={i}>{name}</li>
							))}
						</ul>
					</section>
				</Modal.Body>
				<Modal.Footer>
					<Button
						bsStyle='info'
						className='save-btn'
						fill
						onClick={() => this.setState({ show_info_modal: false })}>
						OK
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	renderHigherEdModal = () => {
		let { idx_higher_ed, submitted, higher_ed_rec } = this.state,
			reciprocity = cloneDeep(this.state.reciprocity),
			{ higher_ed } = reciprocity,
			editing_existing = !!higher_ed.length && idx_higher_ed <= higher_ed.length - 1,
			{ school_name, attended_from, attended_to } = higher_ed_rec,
			header = editing_existing ? `Edit ${higher_ed_rec.school_name}` : 'Add an institution of higher education',
			attended_from_valid = this.higherEdDateIsValid('attended_from'),
			attended_to_valid = this.higherEdDateIsValid('attended_to');

		return (
			<Modal show={idx_higher_ed > -1}>
				<Modal.Header>
					<Modal.Title className='h2'>{header}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Textbox
							disabled={submitted}
							name='school_name'
							value={school_name}
							label='School Name'
							placeholder='School Name'
							onChange={val => this.onChangeHigherEd('school_name', val)}
							required={true}
							cols={12}
						/>
					</Row>
					<Row>
						<DateInput
							value={attended_from}
							onChange={(val, isValid) => this.onChangeHigherEd('attended_from', val)}
							label='Attended From'
							required={true}
							cols={6}
							fullDate={false}
							isInvalid={!attended_from_valid}
						/>
						<DateInput
							value={attended_to}
							onChange={(val, isValid) => this.onChangeHigherEd('attended_to', val)}
							label='Attended To'
							required={true}
							cols={6}
							fullDate={false}
							isInvalid={!attended_to_valid}
						/>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button
						bsStyle='info'
						className='back-btn'
						fill
						onClick={() => this.setState({ higher_ed_rec: cloneDeep(HIGHER_ED), idx_higher_ed: -1 })}>
						Close
					</Button>
					{editing_existing && (
						<Button
							bsStyle='info'
							className='back-btn'
							fill
							onClick={() => {
								reciprocity.higher_ed.splice(idx_higher_ed, 1);
								this.setState({
									reciprocity: reciprocity,
									higher_ed_rec: cloneDeep(HIGHER_ED),
									idx_higher_ed: -1
								});
							}}>
							Delete
						</Button>
					)}
					<Button
						disabled={!school_name.length || !attended_from_valid || !attended_to_valid}
						bsStyle='info'
						className='save-btn'
						fill
						onClick={() => {
							if (editing_existing) {
								reciprocity.higher_ed[idx_higher_ed] = higher_ed_rec;
							} else {
								reciprocity.higher_ed.push(higher_ed_rec);
							}

							this.setState({
								reciprocity: reciprocity,
								higher_ed_rec: cloneDeep(HIGHER_ED),
								idx_higher_ed: -1
							});
						}}>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	renderCampusesModal = () => {
		const { show_campuses_modal, reciprocity, submitted } = this.state,
			{ campuses } = reciprocity,
			opts = {
				mn: colleges.mn,
				mn_state: colleges.mn_state,
				mn_state_college: colleges.mn_state_college
			},
			values = {
				mn: colleges.mn.filter(c => campuses?.includes(c)),
				mn_state: colleges.mn_state.filter(c => campuses?.includes(c)),
				mn_state_college: colleges.mn_state_college.filter(c => campuses?.includes(c))
			};

		if (submitted) {
			Object.keys(opts).forEach(key => {
				opts[key] = opts[key].map(str => {
					return {
						val: str,
						label: str,
						disabled: this.saved_recip.campuses.includes(str)
					};
				});
			});
		}

		return (
			<Modal show={show_campuses_modal}>
				<Modal.Header>
					<Modal.Title className='h2'>Select the campus(es) you plan to attend</Modal.Title>
				</Modal.Header>
				<Modal.Body className='recip-faq'>
					<div className='campus-select-column'>
						<CheckboxList
							name='campuses'
							values={values.mn}
							opts={opts.mn}
							onChange={val => {
								const arr = val.concat(values.mn_state).concat(values.mn_state_college);
								this.onChange('campuses', arr);
							}}
							label='University of Minnesota'
							hide_optional={true}
							cols={12}
							forceNewLines={true}
						/>

						<CheckboxList
							name='campuses'
							values={values.mn_state}
							opts={opts.mn_state}
							onChange={val => {
								const arr = values.mn.concat(val).concat(values.mn_state_college);
								this.onChange('campuses', arr);
							}}
							label='Minnesota State Universities'
							hide_optional={true}
							cols={12}
							forceNewLines={true}
						/>
					</div>
					<div className='campus-select-column'>
						<CheckboxList
							name='campuses'
							values={values.mn_state_college}
							opts={opts.mn_state_college}
							onChange={val => {
								const arr = values.mn.concat(values.mn_state).concat(val);
								this.onChange('campuses', arr);
							}}
							label='Minnesota State Colleges'
							hide_optional={true}
							cols={12}
							forceNewLines={true}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						bsStyle='info'
						className='save-btn'
						fill
						onClick={() => this.setState({ show_campuses_modal: false })}>
						OK
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	renderParentForm = () => {
		let { reciprocity, submitted } = this.state,
			{
				provide_parent_info,
				relationship,
				relationship_addr_street,
				relationship_addr_city,
				relationship_addr_state,
				relationship_addr_zip,
				relationship_us_citizen,
				relationship_visa_type,
				relationship_visa_type_other,
				relationship_lived_wi_12_months,
				relationship_employed,
				relationship_income_taxes,
				relationship_registered_vote,
				relationship_valid_license
			} = reciprocity,
			strRelation = relationship.toLowerCase();

		return (
			<>
				<h2>Parent Information</h2>

				<p>
					Typically, the easiest route to qualify for Minnesota reciprocity is through providing information
					about your parents.
				</p>
				<Row>
					<RadioList
						disabled={submitted}
						name='provide_parent_info'
						value={provide_parent_info}
						label="Do you want to provide a parent's information?"
						opts={['Yes', 'No']}
						onChange={val => this.onChange('provide_parent_info', val)}
						required={true}
						cols={12}
					/>
				</Row>

				{provide_parent_info === 'Yes' && (
					<>
						<p>
							Please select the parent/guardian who best qualifies you for Minnesota reciprocity. If
							neither parent resides in Wisconsin, please list the parent whose information you are most
							familiar with.
						</p>

						<Row>
							<Select
								disabled={submitted}
								name='relationship'
								value={relationship}
								label='Chosen relationship'
								placeholder='Select Relationship'
								opts={['Mother', 'Father', 'Stepmother', 'Stepfather', 'Legal Guardian/Caretaker']}
								required={true}
								cols={6}
								onChange={val => this.onChange('relationship', val)}
								tooltip={
									<a id='relationshipTooltip'>
										<img
											className='informationTooltip'
											src={require('assets/img/Information.png')}
											alt='Information'
										/>
									</a>
								}
							/>
						</Row>

						<Tooltip
							anchorId='relationshipTooltip'
							className='tooltipContainer'
							delayHide={1000}
							effect='solid'
							content={
								<>
									<ul>
										<li>
											<strong>Parent (Mother or Father)</strong>: The student's biological or
											adoptive parent.
										</li>
										<li>
											<strong>Stepparent (Stepmother or Stepfather)</strong>: The legal spouse of
											the student's biological or adoptive parent. A stepparent must be currently
											married to the student's parent.
										</li>
										<li>
											<strong>Legal Guardian/Caretaker</strong>: A legal guardian is a person
											designated by the court to assume care of and make decisions for the
											student. A caretaker is a person designated by an individual (such as the
											student's parent) or an entity other than the court to assume care of and
											make decisions for the student.
										</li>
										<li>
											<strong>Other</strong>: Students may use the “other” category when
											completing the parent information section if they do not have a parent,
											legal guardian, or caretaker whose information they can provide. The “other”
											category may include individuals who do not fit one of the other categories
											listed above.
										</li>
									</ul>
								</>
							}
						/>

						{!!relationship && (
							<>
								<Row>
									<Textbox
										disabled={submitted}
										name='relationship_addr_street'
										value={relationship_addr_street}
										label='Address: Street'
										placeholder='Address - Street'
										onChange={val => this.onChange('relationship_addr_street', val)}
										required={true}
										cols={6}
									/>
									<Textbox
										disabled={submitted}
										name='relationship_addr_city'
										value={relationship_addr_city}
										label='Address: City'
										placeholder='Address - City'
										onChange={val => this.onChange('relationship_addr_city', val)}
										required={true}
										cols={6}
									/>
								</Row>
								<Row>
									<Select
										disabled={submitted}
										name='relationship_addr_state'
										value={relationship_addr_state}
										label='Address: State'
										placeholder='Select State'
										opts={states}
										required={true}
										cols={6}
										onChange={val => this.onChange('relationship_addr_state', val)}
									/>
									<ZipInput
										disabled={submitted}
										name='relationship_addr_zip'
										value={relationship_addr_zip}
										label='Address: Zip / Postal Code'
										placeholder='Address - Zip / Postal Code'
										country='USA'
										required={true}
										cols={6}
										onChange={val => this.onChange('relationship_addr_zip', val)}
									/>
								</Row>
								<Row>
									<RadioList
										disabled={submitted}
										name='relationship_us_citizen'
										value={relationship_us_citizen}
										label={`Is your ${strRelation} a U.S. citizen?`}
										opts={['Yes', 'No', 'Prefer not to respond at this time']}
										onChange={val => this.onChange('relationship_us_citizen', val)}
										required={true}
										cols={12}
									/>
								</Row>

								{relationship_us_citizen === 'No' && (
									<>
										<Row>
											<Select
												disabled={submitted}
												name='relationship_visa_type'
												value={relationship_visa_type}
												label={`${relationship}'s visa type`}
												placeholder='Select Visa Type'
												opts={optsVisa}
												onChange={val => this.onChange('relationship_visa_type', val)}
												required={true}
												cols={6}
											/>

											{relationship_visa_type === 'Other' && (
												<Textbox
													disabled={submitted}
													name='relationship_visa_type_other'
													value={relationship_visa_type_other}
													label="Please explain 'Other' status"
													placeholder="Please explain 'Other' status"
													onChange={val => this.onChange('relationship_visa_type_other', val)}
													required={true}
													cols={6}
													maxLength={100}
												/>
											)}
										</Row>
									</>
								)}

								<Row>
									<RadioList
										disabled={submitted}
										name='relationship_lived_wi_12_months'
										value={relationship_lived_wi_12_months}
										label={`Has your ${strRelation} physically resided full-time in Wisconsin for the past 12 months?`}
										opts={['Yes', 'No']}
										onChange={val => this.onChange('relationship_lived_wi_12_months', val)}
										required={true}
										cols={12}
									/>
								</Row>
								<Row>
									<RadioList
										disabled={submitted}
										name='relationship_employed'
										value={relationship_employed}
										label={`Where is your ${strRelation} employed?`}
										opts={['Wisconsin', 'Outside of Wisconsin', 'Not currently working']}
										onChange={val => this.onChange('relationship_employed', val)}
										required={true}
										cols={12}
									/>
								</Row>
								<Row>
									<RadioList
										disabled={submitted}
										name='relationship_income_taxes'
										value={relationship_income_taxes}
										label={`Has your ${strRelation} filed a Wisconsin resident income tax return for the most recent tax year?`}
										opts={['Yes', 'No, did not file', 'No, filed in a different state']}
										onChange={val => this.onChange('relationship_income_taxes', val)}
										required={true}
										cols={12}
									/>
								</Row>
								<Row>
									<RadioList
										disabled={submitted}
										name='relationship_registered_vote'
										value={relationship_registered_vote}
										label={`Was Wisconsin the last place your ${strRelation} registered to vote or voted?`}
										opts={['Yes', 'No']}
										onChange={val => this.onChange('relationship_registered_vote', val)}
										required={true}
										cols={12}
									/>
								</Row>
								<Row>
									<RadioList
										disabled={submitted}
										name='relationship_valid_license'
										value={relationship_valid_license}
										label={`Does your ${strRelation} hold a valid Wisconsin driver's license?`}
										opts={['Yes', 'No']}
										onChange={val => this.onChange('relationship_valid_license', val)}
										required={true}
										cols={12}
									/>
								</Row>
							</>
						)}
					</>
				)}
			</>
		);
	};

	renderForm = () => {
		let { visa_types } = this.props,
			{ reciprocity, ssn_confirm, submitted, available_terms } = this.state,
			{
				campuses,
				gender,
				dob,
				ssn,
				us_citizen,
				visa_type,
				visa_type_other,
				addr_street,
				addr_city,
				addr_state,
				addr_zip,
				phone,
				hs_name,
				hs_city,
				hs_state,
				hs_grad_date,
				level_of_study,
				self_live_in_wi,
				self_lived_wi_date,
				self_employed,
				self_income_taxes,
				self_registered_vote,
				self_valid_license,
				self_claim_dependent,
				self_lived_wi_12_months,
				self_he_enrolled,
				higher_ed
			} = reciprocity,
			current_term = `${reciprocity.term_name} ${reciprocity.term_year}`,
			invalid_wi_lived_date = !!self_lived_wi_date && months_passed(self_lived_wi_date) < 0,
			optsVisaType = opts_visa_types(visa_types),
			selectedVisaType = optsVisaType.find(opt => opt.id === visa_type);

		return (
			<>
				<hr />

				<Row>
					<Select
						name='term'
						value={current_term}
						label='Term'
						placeholder='Select Term'
						opts={available_terms}
						onChange={val => this.onChange('term', val)}
						required={true}
						cols={6}
						disabled={submitted}
					/>
				</Row>
				<Row>
					<div className='col-md-6'>
						<p>What school(s) do you plan to attend?</p>
						<Button
							onClick={() => this.setState({ show_campuses_modal: true })}
							className='save-btn btn-recip-list'>
							Select campuses
						</Button>
					</div>

					{!!campuses.length && (
						<div className='col-md-6'>
							<p className='recip-list'>
								{campuses.map(str => (
									<span key={str}>{str}</span>
								))}
							</p>
						</div>
					)}
				</Row>
				<Row>
					<Select
						disabled={submitted}
						name='gender'
						value={gender}
						label='Legal Sex'
						placeholder='Select Legal Sex'
						opts={legal_genders}
						onChange={val => this.onChange('gender', val)}
						required={true}
						cols={6}
					/>
					<DateInput
						disabled={submitted}
						value={dob}
						label='Date of Birth'
						fullDate={true}
						onChange={(val, isValid) => this.onChange('dob', val)}
						required={true}
						cols={6}
						isInvalid={!IS_VALID.date_of_birth(dob)}
					/>
				</Row>
				<Row>
					<Textbox
						disabled={submitted}
						type='ssn'
						name='ssn'
						value={ssn}
						label='US Social Security Number'
						onChange={val => this.onChange('ssn', val)}
						required={true}
						cols={6}
						isInvalid={!IS_VALID.ssn(ssn)}
					/>

					<Textbox
						disabled={submitted}
						type='ssn'
						name='ssn_confirm'
						value={ssn_confirm}
						label='Confirm Social Security Number'
						onChange={val => this.setState({ ssn_confirm: val })}
						required={true}
						cols={6}
						isInvalid={ssn !== ssn_confirm}
					/>
				</Row>
				<Row>
					<Select
						disabled={submitted}
						name='us_citizen'
						value={us_citizen}
						label='Are you a US Citizen?'
						placeholder='(Select)'
						opts={['Yes', 'No']}
						onChange={val => this.onChange('us_citizen', val)}
						required={true}
						cols={6}
					/>
				</Row>
				{us_citizen === 'No' && (
					<Row>
						<Select
							disabled={submitted}
							value={visa_type}
							label='Select Visa Type'
							opts={optsVisaType.map(rec => ({ val: rec.id, label: rec.title }))}
							onChange={val => this.onChange('visa_type', val)}
							name='visa_type'
							placeholder='Select Visa Type'
							cols={6}
							required={true}
						/>

						{selectedVisaType?.title === 'Other' && (
							<Textbox
								disabled={submitted}
								label="Please explain 'Other' status"
								placeholder="Please explain 'Other' status"
								name='visa_type_other'
								value={visa_type_other}
								onChange={val => this.onChange('visa_type_other', val)}
								required={true}
								cols={6}
							/>
						)}
					</Row>
				)}
				<Row>
					<Select
						disabled={submitted}
						name='self_live_in_wi'
						value={self_live_in_wi}
						label='Do you live in Wisconsin?'
						placeholder='Select Option'
						opts={['No', "Yes, but I haven't always", 'Yes, my entire life']}
						required={true}
						cols={6}
						onChange={val => this.onChange('self_live_in_wi', val)}
					/>

					{self_live_in_wi === "Yes, but I haven't always" && (
						<DateInput
							disabled={submitted}
							value={self_lived_wi_date}
							label='When did you start living in Wisconsin?'
							onChange={(val, isValid) => this.onChange('self_lived_wi_date', val)}
							fullDate={false}
							required={true}
							cols={6}
							isInvalid={invalid_wi_lived_date}
						/>
					)}
				</Row>

				<Row>
					<Select
						disabled={submitted}
						name='level_of_study'
						value={level_of_study}
						label='Current Level of Study'
						placeholder='Select Level of Study'
						opts={levels_of_study}
						onChange={val => this.onChange('level_of_study', val)}
						required={true}
						cols={6}
					/>

					<PhoneControl
						disabled={submitted}
						name='phone'
						value={phone}
						label='Phone'
						required={true}
						cols={6}
						onChange={val => this.onChange('phone', val)}
					/>
				</Row>
				<Row>
					<Textbox
						disabled={submitted}
						name='addr_street'
						value={addr_street}
						label='Address: Street'
						placeholder='Address - Street'
						onChange={val => this.onChange('addr_street', val)}
						required={true}
						cols={6}
					/>
					<Textbox
						disabled={submitted}
						name='addr_city'
						value={addr_city}
						label='Address: City'
						placeholder='Address - City'
						onChange={val => this.onChange('addr_city', val)}
						required={true}
						cols={6}
					/>
				</Row>
				<Row>
					<Select
						disabled={submitted}
						name='addr_state'
						value={addr_state}
						label='Address: State'
						placeholder='Select State'
						opts={states}
						required={true}
						cols={6}
						onChange={val => this.onChange('addr_state', val)}
					/>
					<ZipInput
						disabled={submitted}
						name='addr_zip'
						value={addr_zip}
						label='Address: Zip / Postal Code'
						placeholder='Address - Zip / Postal Code'
						country='USA'
						required={true}
						cols={6}
						onChange={val => this.onChange('addr_zip', val)}
					/>
				</Row>
				<Row>
					<RadioList
						disabled={submitted}
						name='self_employed'
						value={self_employed}
						label='Where are you employed?'
						opts={['Wisconsin', 'Outside of Wisconsin', 'Not currently working']}
						onChange={val => this.onChange('self_employed', val)}
						required={true}
						cols={12}
					/>
				</Row>
				<Row>
					<RadioList
						disabled={submitted}
						name='self_income_taxes'
						value={self_income_taxes}
						label='Have you filed a Wisconsin resident income tax return for the most recent tax year?'
						opts={['Yes', 'No, did not file', 'No, filed in a different state']}
						onChange={val => this.onChange('self_income_taxes', val)}
						required={true}
						cols={12}
					/>
				</Row>
				<Row>
					<RadioList
						disabled={submitted}
						name='self_registered_vote'
						value={self_registered_vote}
						label='Was Wisconsin the last place you registered to vote or voted?'
						opts={['Yes', 'No']}
						onChange={val => this.onChange('self_registered_vote', val)}
						required={true}
						cols={12}
						tooltip={
							<a id='voteTooltip'>
								<img
									className='informationTooltip'
									src={require('assets/img/Information.png')}
									alt='Information'
								/>
							</a>
						}
					/>

					<Tooltip
						anchorId='voteTooltip'
						className='tooltipContainer'
						delayHide={1000}
						effect='solid'
						content={<p>If you are under the age of 18, please select no.</p>}
					/>
				</Row>
				<Row>
					<RadioList
						disabled={submitted}
						name='self_valid_license'
						value={self_valid_license}
						label="Do you hold a valid Wisconsin driver's license?"
						opts={['Yes', 'No']}
						onChange={val => this.onChange('self_valid_license', val)}
						required={true}
						cols={12}
					/>
				</Row>
				<Row>
					<RadioList
						disabled={submitted}
						name='self_claim_dependent'
						value={self_claim_dependent}
						label='Did your parent(s)/guardian(s) claim you as a dependent for the most recent tax year?'
						opts={['Yes', 'No']}
						onChange={val => this.onChange('self_claim_dependent', val)}
						required={true}
						cols={12}
					/>
				</Row>
				<Row>
					<RadioList
						disabled={submitted}
						name='self_lived_wi_12_months'
						value={self_lived_wi_12_months}
						label='Have you physically resided full-time in Wisconsin for the past 12 months?'
						opts={['Yes', 'No']}
						onChange={val => this.onChange('self_lived_wi_12_months', val)}
						required={true}
						cols={12}
					/>
				</Row>
				<Row>
					<RadioList
						disabled={submitted}
						name='self_he_enrolled'
						value={self_he_enrolled}
						label="Have you been enrolled at any institutions of higher education during the twelve months prior to today's date?"
						opts={['Yes', 'No']}
						onChange={val => this.onChange('self_he_enrolled', val)}
						required={true}
						cols={12}
					/>
				</Row>

				<h2>Educational History</h2>

				<Row>
					<Textbox
						disabled={submitted}
						name='hs_name'
						value={hs_name}
						label='High School Name'
						placeholder='High School - Name'
						onChange={val => this.onChange('hs_name', val)}
						required={true}
						cols={6}
					/>
					<DateInput
						disabled={submitted}
						value={hs_grad_date}
						onChange={(val, isValid) => this.onChange('hs_grad_date', val)}
						label='High School Graduation Date'
						required={true}
						cols={6}
						fullDate={false}
						isInvalid={!IS_VALID.grad_date(hs_grad_date)}
					/>
				</Row>
				<Row>
					<Textbox
						disabled={submitted}
						name='hs_city'
						value={hs_city}
						label='High School - City'
						placeholder='High School - City'
						onChange={val => this.onChange('hs_city', val)}
						required={true}
						cols={6}
					/>
					<Select
						disabled={submitted}
						name='hs_state'
						value={hs_state}
						label='High School - State'
						placeholder='Select State'
						opts={states}
						required={true}
						cols={6}
						onChange={val => this.onChange('hs_state', val)}
					/>
				</Row>
				<Row className='higher-ed-row'>
					<div className='col-md-12'>
						<p>
							All institutions of higher education currently attending or previously attended{' '}
							<small>(Optional)</small>:
						</p>
					</div>

					{!submitted && (
						<div className='col-md-6'>
							<p>
								<Button
									onClick={() => this.setState({ idx_higher_ed: higher_ed.length })}
									className='save-btn btn-recip-list'>
									Add institution
								</Button>
							</p>
						</div>
					)}

					{!!higher_ed.length && (
						<div className='col-md-6'>
							<p className='recip-list higher-ed'>
								{higher_ed.map((rec, i) => {
									const { school_name, attended_from, attended_to } = rec,
										str = `${school_name}, ${formatMonth(attended_from)} - ${formatMonth(
											attended_to
										)}`;

									return submitted ? (
										<span key={i}>{str}</span>
									) : (
										<a
											key={i}
											onClick={() => this.setState({ higher_ed_rec: rec, idx_higher_ed: i })}>
											{str}
										</a>
									);
								})}
							</p>
						</div>
					)}
				</Row>

				{this.renderParentForm()}
			</>
		);
	};

	renderRecordTable = () => {
		const { reciprocity } = this.props;

		return (
			<>
				{Object.keys(reciprocity).map(i => {
					const recip = reciprocity[i],
						{ submit_date, campuses } = recip,
						submitted = (recip.submit_date || empty_date) !== empty_date;

					return (
						<React.Fragment key={i}>
							<div className='mn-recip-record'>
								<div data-submitted={submitted}>
									<p>
										{recip.term_name} {recip.term_year}
										{submitted && (
											<span>Submitted {formatDateTime(submit_date, true, true, true)}</span>
										)}
									</p>

									<a
										onClick={() => {
											this.saved_recip = cloneDeep(recip);

											this.setState({
												current_id: i,
												reciprocity: cloneDeep(recip),
												ssn_confirm: recip.ssn,
												submitted: submitted
											});
										}}>
										{submitted ? (
											<>
												Add Campuses /<br />
												View Application
											</>
										) : (
											'Edit'
										)}
									</a>
								</div>

								{campuses.map(c => (
									<span key={c}>{c}</span>
								))}

								<p></p>
							</div>
						</React.Fragment>
					);
				})}
			</>
		);
	};

	renderButtons = () => {
		let { reciprocity: saved_recip } = this.props,
			{ show_saved, submitted, blockers, current_id, available_terms } = this.state;

		if (!current_id) {
			if (available_terms.length) {
				return (
					<Button
						form='form'
						className='save-btn'
						fill
						type='submit'
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();

							this.onChangeCurrentId(-1);
						}}>
						Create New Application
					</Button>
				);
			}
		} else {
			const canSave = this.canSave(),
				submitted_app_exists = Object.keys(saved_recip).some(
					i => (saved_recip[i].submit_date || empty_date) !== empty_date
				);

			return (
				<>
					{submitted_app_exists && (
						<Button
							form='form'
							className='back-btn'
							fill
							type='submit'
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();

								this.onChangeCurrentId(0);
							}}>
							Back
						</Button>
					)}

					<Button
						form='form'
						className={submitted ? 'save-btn' : 'back-btn'}
						fill
						type='submit'
						disabled={!canSave || show_saved}
						onClick={e => this.onSave(e)}>
						{show_saved ? 'Saved!' : 'Save'}
					</Button>

					{!submitted && (
						<>
							<Button
								form='form'
								className='save-btn'
								fill
								type='submit'
								disabled={!!blockers.length || canSave}
								onClick={e => this.onSubmit(e)}>
								Submit
							</Button>

							<a
								id='blockersTooltip'
								className={`info-submit-wrapper ${
									blockers.length ? '' : 'info-submit-wrapper-hidden'
								}`}>
								<img
									className='informationTooltip'
									src={require('assets/img/Information-button.png')}
									alt='Information'
								/>
							</a>

							<Tooltip
								anchorId='blockersTooltip'
								className='tooltipContainer'
								delayHide={1000}
								effect='solid'
								content={blockers.map(str => (
									<span className='blocker-tooltip-item' key={str}>
										{str}
									</span>
								))}
							/>
						</>
					)}
				</>
			);
		}

		return <></>;
	};

	render = () => {
		let { submitted, current_id } = this.state;

		return (
			<Card
				title='Minnesota Reciprocity'
				content={
					<form id='form'>
						{current_id ? (
							<>
								{this.renderInfoModal()}
								{this.renderCampusesModal()}
								{this.renderHigherEdModal()}

								{submitted && (
									<p className='mn-recip-submitted'>
										This application has been submitted, but you may add additional campuses and
										re-submit.
									</p>
								)}

								<p>
									This application should be used for Wisconsin residents looking to attend a public
									Minnesota school only.
								</p>
								<p>
									<a onClick={() => this.setState({ show_info_modal: true })}>More information</a>
								</p>

								{this.renderForm()}

								<hr />

								<p>
									If you, or the institution where you are enrolled, do not receive notification of
									your renewal status within three weeks of your application, please contact the
									Universities of Wisconsin.
								</p>
							</>
						) : (
							this.renderRecordTable()
						)}
					</form>
				}
				buttons={this.renderButtons()}
			/>
		);
	};
}

const mapStateToProps = state => ({
		reciprocity: state.user.reciprocity,
		apps: state.user.apps,
		user_id: state.user.user_id,
		visa_types: state.global.visa_types
	}),
	mapDispatchToProps = dispatch => ({
		login: obj => dispatch(UserActions.login(obj)),
		setUserProperty: (str_prop, val) => dispatch(UserActions.setUserProperty(str_prop, val)),
		captureError: err => dispatch(GlobalActions.captureError(err)),
		updateReciprocity: obj => dispatch(UserActions.updateReciprocity(obj))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reciprocity));
