import React from 'react';

import { APP_ROUTES } from 'components/modules/nav';
import { APP_TYPES } from 'components/modules/app';

//Initial information
import SelectAppModifier from 'views/InitialInformation/SelectAppModifier';
import SelectCampus from 'views/InitialInformation/SelectCampus/SelectCampus';
import DirectAdmitSelectCampus from 'views/InitialInformation/SelectCampus/DirectAdmit';
import SelectSemester from 'views/InitialInformation/SelectSemester';
import SelectMajor from 'views/InitialInformation/SelectMajor';
import Summary from 'views/InitialInformation/Summary';

//Personal information
import PersonalInformationParent from 'views/PersonalInformation/_Main';
import BasicInformation from 'views/PersonalInformation/BasicInformation';
import PersonalInformationAdditional from 'views/PersonalInformation/Additional';
import ContactInformation from 'views/PersonalInformation/ContactInformation';
import ParentInfo from 'views/PersonalInformation/ParentInfo';
import Residency from 'views/PersonalInformation/Residency';

//Academic background
import AcademicBackground from 'views/AcademicBackground/_Main';
import HigherEducation from 'views/AcademicBackground/HigherEd';
import HighSchool from 'views/AcademicBackground/HighSchool';
import HSCourses from 'views/AcademicBackground/HighSchoolCourses';
import TestScores from 'views/AcademicBackground/TestScores';
import HigherEdCheck from 'views/AcademicBackground/HigherEdCheck';
import HigherEdCourses from 'views/AcademicBackground/HigherEdCourses';

//Holistic Background
import HolisticBackground from 'views/HolisticBackground/_Main';
import Activities from 'views/HolisticBackground/Activities';
import Essay from 'views/HolisticBackground/Essay';
import Employment from 'views/HolisticBackground/Employment';

//Submit
import Submit from 'views/Submit/_Main';
import AdditionalApplications from 'views/Submit/AdditionalApplications';
import SignApplication from 'views/Submit/SignApplication';
import ApplicationPayment from 'views/Submit/ApplicationPayment';
import NextSteps from 'views/Submit/NextSteps';

//Public routes
import SignIn from 'views/Public/SignIn';
import ResetPassword from 'views/Public/ResetPassword';
import ResetPasswordForm from 'views/Public/ResetPasswordForm';
import CreateAccount from 'views/Public/CreateAccount';
import LinkDirectAdmit from 'views/Public/LinkDirectAdmit';
import Index from 'views/Public/home-pages/Index';
import DirectAdmit from 'views/Public/home-pages/DirectAdmit';
import MN_Reciprocity from 'views/Public/home-pages/MN-Reciprocity';
import HowToApply from 'views/Public/HowToApply';
import FindYourTerm from 'views/Public/FindYourTerm';
import FindAProgram from 'views/Public/FindAProgram';
import ContactUs from 'views/Public/ContactUs';

//Authenticated routes
import MyAccount from 'views/Authenticated/MyAccount';
import VerifyEmail from 'views/Authenticated/VerifyEmail';
import AccountInformation from 'views/Authenticated/AccountInformation';
import Reciprocity from 'views/Authenticated/Reciprocity';
import MyDocuments from 'views/Authenticated/MyDocuments';

const all_app_types = Object.keys(APP_TYPES),
	routes = [
		{
			path: '/select-modifier',
			name: 'Initial Information',
			element: <SelectAppModifier />,
			navMenu: 'none',
			completedBoolean: 'initialInformationCompleted',
			enabledModifiers: all_app_types.filter(x => x !== 'DA'),
			requireAuthentication: true,
			dataDependencies: ['visa_types'],
			preloadedData: ['campuses', 'campus_exceptions', 'terms', 'cdrMajors', 'specials', 'career_clusters'],
			initInfoPage: true
		},
		{
			path: '/select-campus',
			name: 'Select Campus',
			element: <SelectCampus />,
			navMenu: 'none',
			completedBoolean: 'selectCampusCompleted',
			enabledModifiers: all_app_types.filter(x => x !== 'DA'),
			requireAuthentication: true,
			dataDependencies: ['campuses', 'campus_exceptions', 'terms'],
			preloadedData: ['cdrMajors', 'specials', 'career_clusters'],
			initInfoPage: true
		},
		{
			path: '/direct-admit/select-campus',
			name: 'Direct Admit - Select Campus',
			element: <DirectAdmitSelectCampus />,
			navMenu: 'none',
			completedBoolean: 'selectCampusCompleted',
			enabledModifiers: ['DA'],
			requireAuthentication: true,
			dataDependencies: ['campuses', 'terms'],
			preloadedData: ['cdrMajors', 'specials', 'career_clusters'],
			initInfoPage: true
		},
		{
			path: '/course-of-study',
			name: 'Course of Study',
			element: <SelectMajor />,
			navMenu: 'none',
			completedBoolean: 'selectAreaStudyCompleted',
			enabledModifiers: all_app_types,
			requireAuthentication: true,
			dataDependencies: ['cdrMajors', 'specials', 'career_clusters', 'terms', 'campuses'],
			initInfoPage: true
		},
		{
			path: '/select-semester',
			name: 'Select Semester',
			element: <SelectSemester />,
			navMenu: 'none',
			completedBoolean: 'selectSemesterCompleted',
			enabledModifiers: all_app_types.filter(x => x !== 'DA'),
			requireAuthentication: true,
			dataDependencies: ['terms'],
			preloadedData: ['campuses'],
			initInfoPage: true
		},
		{
			path: '/summary',
			name: 'Summary',
			element: <Summary />,
			navMenu: 'none',
			completedBoolean: 'summaryCompleted',
			enabledModifiers: all_app_types,
			requireAuthentication: true,
			dataDependencies: ['campuses', 'terms', 'essay_prompts'],
			initInfoPage: true
		},
		{
			path: '/personal-information',
			name: 'Personal Information',
			element: <PersonalInformationParent />,
			navMenu: 'full',
			submenuName: 'personal_info_open',
			parentDropdown: true,
			completedBoolean: 'personalInformationCompleted',
			enabledModifiers: all_app_types,
			loggedInRoutes: APP_ROUTES,
			showProgressBar: true,
			preloadedData: ['waivers'],
			requireAppId: true
		},
		{
			path: '/basic-information',
			name: 'Basic',
			element: <BasicInformation />,
			navMenu: 'full',
			childOf: '/personal-information',
			completedBoolean: 'basicInformationCompleted',
			enabledModifiers: all_app_types,
			loggedInRoutes: APP_ROUTES,
			showProgressBar: true,
			requireAppId: true
		},
		{
			path: '/personal-information-additional',
			name: 'Additional',
			element: <PersonalInformationAdditional />,
			navMenu: 'full',
			childOf: '/personal-information',
			completedBoolean: 'personalInformationAdditionalCompleted',
			enabledModifiers: ['FM_D', 'TR_D', 'RE_D', 'RET_D', 'GR_D', 'HS_D_S', 'UG_D_S', 'GR_D_S', 'DA'],
			loggedInRoutes: APP_ROUTES,
			showProgressBar: true,
			dataDependencies: ['waivers'],
			requireAppId: true
		},
		{
			path: '/contact-information',
			name: 'Contact',
			element: <ContactInformation />,
			navMenu: 'full',
			childOf: '/personal-information',
			completedBoolean: 'contactInfoCompleted',
			enabledModifiers: all_app_types,
			loggedInRoutes: APP_ROUTES,
			showProgressBar: true,
			requireAppId: true
		},
		{
			path: '/parent-information',
			name: 'Parent/Guardian',
			element: <ParentInfo />,
			navMenu: 'full',
			childOf: '/personal-information',
			completedBoolean: 'parentInfoCompleted',
			enabledModifiers: ['FM_D', 'FM_I', 'TR_D', 'TR_I', 'RE_D', 'RET_D', 'HS_D_S', 'UG_D_S', 'DA'],
			loggedInRoutes: APP_ROUTES,
			showProgressBar: true,
			requireAppId: true
		},
		{
			path: '/residency',
			name: 'Residency',
			element: <Residency />,
			navMenu: 'full',
			completedBoolean: 'residencyCompleted',
			childOf: '/personal-information',
			enabledModifiers: ['FM_D', 'TR_D', 'RE_D', 'RET_D', 'GR_D', 'HS_D_S', 'UG_D_S', 'GR_D_S', 'DA'],
			loggedInRoutes: APP_ROUTES,
			showProgressBar: true,
			dataDependencies: ['visa_types'],
			requireAppId: true
		},
		{
			path: '/academic-background',
			name: 'Academic Background',
			element: <AcademicBackground />,
			navMenu: 'full',
			submenuName: 'academic_background_open',
			parentDropdown: true,
			completedBoolean: 'academicBackgroundCompleted',
			enabledModifiers: all_app_types,
			loggedInRoutes: APP_ROUTES,
			showProgressBar: true,
			requireAppId: true
		},
		{
			path: '/academic-background-hs',
			name: 'High School',
			element: <HighSchool />,
			navMenu: 'full',
			childOf: '/academic-background',
			completedBoolean: 'academicBackgroundHSCompleted',
			enabledModifiers: ['FM_D', 'FM_I', 'TR_D', 'TR_I', 'HS_D_S', 'UG_D_S', 'UG_I_S', 'HS_I_S', 'DA'],
			loggedInRoutes: APP_ROUTES,
			showProgressBar: true,
			requireAppId: true
		},
		{
			path: '/academic-background-hs-courses',
			name: 'High School Courses',
			element: <HSCourses />,
			navMenu: 'full',
			childOf: '/academic-background',
			completedBoolean: 'academicBackgroundHSCoursesCompleted',
			enabledModifiers: ['FM_D', 'FM_I', 'DA'],
			loggedInRoutes: APP_ROUTES,
			showProgressBar: true,
			requireAppId: true
		},
		{
			path: '/academic-background-higher-education-check',
			name: 'Higher Education Check',
			element: <HigherEdCheck />,
			navMenu: 'full',
			childOf: '/academic-background',
			completedBoolean: 'academicBackgroundHigherEdCheckCompleted',
			enabledModifiers: ['FM_D', 'FM_I', 'UG_D_S', 'UG_I_S', 'DA'],
			loggedInRoutes: APP_ROUTES,
			showProgressBar: true,
			requireAppId: true
		},
		{
			path: '/academic-background-higher-education',
			name: 'College/Post-Secondary',
			element: <HigherEducation />,
			navMenu: 'full',
			childOf: '/academic-background',
			completedBoolean: 'academicBackgroundCollegeCompleted',
			enabledModifiers: all_app_types.filter(x => !['HS_D_S', 'HS_I_S'].includes(x)),
			loggedInRoutes: APP_ROUTES,
			showProgressBar: true,
			requireAppId: true
		},
		{
			path: '/academic-background-higher-education-courses',
			name: 'College/Post-Secondary Courses',
			element: <HigherEdCourses />,
			navMenu: 'full',
			childOf: '/academic-background',
			completedBoolean: 'academicBackgroundCollegeCoursesCompleted',
			enabledModifiers: ['FM_D', 'FM_I', 'TR_D', 'TR_I', 'RE_D', 'RE_I', 'RET_D', 'RET_I', 'GR_D', 'GR_I', 'DA'],
			loggedInRoutes: APP_ROUTES,
			showProgressBar: true,
			requireAppId: true
		},
		{
			path: '/academic-background-test-scores',
			name: 'Test Scores',
			element: <TestScores />,
			navMenu: 'full',
			childOf: '/academic-background',
			completedBoolean: 'academicBackgroundTestScoresCompleted',
			enabledModifiers: ['FM_D', 'FM_I', 'TR_I', 'GR_I', 'GR_I_S'],
			loggedInRoutes: APP_ROUTES,
			showProgressBar: true,
			requireAppId: true
		},
		{
			path: '/holistic-background',
			name: 'Holistic Background',
			element: <HolisticBackground />,
			navMenu: 'full',
			submenuName: 'holistic_background_open',
			parentDropdown: true,
			completedBoolean: 'holisticBackgroundCompleted',
			enabledModifiers: all_app_types.filter(x => x !== 'DA'),
			loggedInRoutes: APP_ROUTES,
			showProgressBar: true,
			preloadedData: ['activities', 'essay_prompts'],
			requireAppId: true
		},
		{
			path: '/activities',
			name: 'Activities',
			element: <Activities />,
			navMenu: 'full',
			completedBoolean: 'activitiesCompleted',
			childOf: '/holistic-background',
			enabledModifiers: ['FM_D', 'FM_I', 'TR_D', 'TR_I'],
			loggedInRoutes: APP_ROUTES,
			showProgressBar: true,
			dataDependencies: ['activities'],
			requireAppId: true
		},
		{
			path: '/employment',
			name: 'Employment',
			element: <Employment />,
			navMenu: 'full',
			completedBoolean: 'employmentCompleted',
			childOf: '/holistic-background',
			enabledModifiers: ['FM_D', 'FM_I', 'TR_D', 'TR_I', 'RE_D', 'RET_D', 'RE_I', 'RET_I', 'GR_D', 'GR_I'],
			loggedInRoutes: APP_ROUTES,
			showProgressBar: true,
			requireAppId: true
		},
		{
			path: '/essay',
			name: 'Essay',
			element: <Essay />,
			navMenu: 'full',
			completedBoolean: 'essayCompleted',
			childOf: '/holistic-background',
			enabledModifiers: all_app_types.filter(x => x !== 'DA'),
			loggedInRoutes: APP_ROUTES,
			showProgressBar: true,
			dataDependencies: ['essay_prompts'],
			requireAppId: true
		},
		{
			path: '/submit',
			name: 'Submit',
			element: <Submit />,
			navMenu: 'full',
			parentDropdown: true,
			submenuName: 'submit_open',
			enabledModifiers: all_app_types,
			loggedInRoutes: APP_ROUTES,
			showProgressBar: true,
			submitBoolean: 'submit',
			dataDependencies: ['terms', 'waivers', 'cdrMajors', 'campuses'],
			preloadedData: ['campus_next_steps'],
			requireAppId: true
		},
		{
			path: '/additional-applications',
			name: 'Additional Applications',
			element: <AdditionalApplications />,
			navMenu: 'full',
			childOf: '/submit',
			enabledModifiers: ['FM_D', 'FM_I', 'TR_D', 'TR_I'],
			loggedInRoutes: APP_ROUTES,
			showProgressBar: true,
			submitBoolean: 'additional',
			dataDependencies: ['terms'],
			requireAppId: true
		},
		{
			path: '/sign-application',
			name: 'Sign Application',
			element: <SignApplication />,
			navMenu: 'full',
			childOf: '/submit',
			enabledModifiers: all_app_types,
			loggedInRoutes: APP_ROUTES,
			showProgressBar: true,
			submitBoolean: 'sign',
			dataDependencies: ['cdrMajors', 'terms', 'campuses'],
			requireAppId: true
		},
		{
			path: '/payment',
			name: 'Payment',
			element: <ApplicationPayment />,
			navMenu: 'none',
			childOf: '/submit',
			enabledModifiers: all_app_types,
			loggedInRoutes: APP_ROUTES,
			showProgressBar: true,
			submitBoolean: 'payment',
			dataDependencies: ['terms'],
			requireAppId: true
		},
		{
			path: '/next-steps',
			name: 'Next Steps',
			element: <NextSteps />,
			navMenu: 'none',
			childOf: '/submit',
			enabledModifiers: all_app_types,
			loggedInRoutes: APP_ROUTES,
			showProgressBar: true,
			submitBoolean: 'nextSteps',
			dataDependencies: ['campus_next_steps'],
			requireAppId: true
		},

		//NON APP ROUTES / PUBLIC ROUTES
		{
			path: '/',
			name: 'Home',
			element: <Index />,
			requireAnonymous: true,
			navMenu: 'none',
			loggedInRoutes: [
				'/how-to-apply',
				'/find-your-term',
				'/find-a-program',
				'/my-account',
				'/contact-us',
				'/sign-out'
			],
			preAuthRoutes: [
				'/',
				'/sign-in',
				'/create-account',
				'/reset-password',
				'/direct-admit',
				'/how-to-apply',
				'/find-your-term',
				'/find-a-program',
				'/contact-us'
			],
			dataDependencies: ['system_notifications', 'paper_application_url']
		},
		{
			path: '/sign-in',
			name: 'Sign In',
			element: <SignIn />,
			requireAnonymous: true,
			navMenu: 'basic',
			preAuthRoutes: [
				'/',
				'/sign-in',
				'/create-account',
				'/reset-password',
				'/how-to-apply',
				'/find-your-term',
				'/find-a-program',
				'/contact-us'
			],
			dataDependencies: ['system_notifications']
		},
		{
			path: '/create-account',
			name: 'Create Account',
			element: <CreateAccount />,
			requireAnonymous: true,
			navMenu: 'basic',
			preAuthRoutes: [
				'/',
				'/sign-in',
				'/create-account',
				'/reset-password',
				'/how-to-apply',
				'/find-your-term',
				'/find-a-program',
				'/contact-us'
			],
			dataDependencies: ['system_notifications']
		},
		{
			path: '/verify',
			name: 'Verify Email',
			element: <VerifyEmail />,
			requireAuthentication: true,
			navMenu: 'none',
			childOf: '/my-account'
		},
		{
			path: '/reset-password',
			name: 'Reset Password',
			element: <ResetPassword />,
			requireAnonymous: true,
			childOf: '/my-account',
			navMenu: 'basic',
			preAuthRoutes: [
				'/',
				'/sign-in',
				'/create-account',
				'/reset-password',
				'/how-to-apply',
				'/find-your-term',
				'/find-a-program',
				'/contact-us'
			]
		},
		{
			path: '/reset-password-form',
			name: 'Reset Password',
			element: <ResetPasswordForm />,
			navMenu: 'none',
			preAuthRoutes: ['/', '/sign-in', '/create-account', '/reset-password', '/contact-us']
		},
		{
			path: '/direct-admit',
			name: 'Direct Admit',
			element: <DirectAdmit />,
			requireAnonymous: true,
			navMenu: 'none',
			loggedInRoutes: [
				'/how-to-apply',
				'/find-your-term',
				'/find-a-program',
				'/my-account',
				'/contact-us',
				'/sign-out'
			],
			preAuthRoutes: [
				'/sign-in',
				'/create-account',
				'/reset-password',
				'/direct-admit',
				'/how-to-apply',
				'/find-your-term',
				'/find-a-program',
				'/contact-us'
			],
			dataDependencies: ['system_notifications', 'paper_application_url']
		},
		{
			path: '/mn-reciprocity',
			name: 'Minnesota Reciprocity',
			element: <MN_Reciprocity />,
			requireAnonymous: true,
			navMenu: 'none',
			loggedInRoutes: [
				'/how-to-apply',
				'/find-your-term',
				'/find-a-program',
				'/my-account',
				'/contact-us',
				'/sign-out'
			],
			preAuthRoutes: [
				'/sign-in',
				'/create-account',
				'/reset-password',
				'/direct-admit',
				'/how-to-apply',
				'/find-your-term',
				'/find-a-program',
				'/contact-us'
			],
			dataDependencies: ['system_notifications', 'paper_application_url']
		},
		{
			path: '/link-direct-admit',
			name: 'Direct Admit',
			element: <LinkDirectAdmit />,
			navMenu: 'basic',
			requireAuthentication: true,
			loggedInRoutes: [
				'/how-to-apply',
				'/find-your-term',
				'/find-a-program',
				'/my-account',
				'/contact-us',
				'/sign-out'
			],
			preAuthRoutes: [
				'/',
				'/sign-in',
				'/create-account',
				'/reset-password',
				'/how-to-apply',
				'/find-your-term',
				'/find-a-program',
				'/contact-us'
			],
			dataDependencies: ['system_notifications', 'paper_application_url']
		},
		{
			path: '/my-account',
			name: 'My Account',
			element: <MyAccount />,
			navMenu: 'full',
			loggedInRoutes: [
				'/my-account',
				'/how-to-apply',
				'/find-your-term',
				'/find-a-program',
				'/contact-us',
				'/sign-out'
			],
			requireAuthentication: true,
			dataDependencies: ['terms', 'essay_prompts'],
			preloadedData: ['campuses']
		},
		{
			path: '/account',
			name: 'Account Information',
			element: <AccountInformation />,
			navMenu: 'full',
			childOf: '/my-account',
			loggedInRoutes: [
				'/my-account',
				'/how-to-apply',
				'/find-your-term',
				'/find-a-program',
				'/contact-us',
				'/sign-out'
			],
			requireAuthentication: true
		},
		{
			path: '/reciprocity',
			name: 'Minnesota Reciprocity',
			element: <Reciprocity />,
			navMenu: 'full',
			childOf: '/my-account',
			loggedInRoutes: ['/my-account', '/contact-us', '/sign-out'],
			requireAuthentication: true,
			dataDependencies: ['terms', 'visa_types']
		},
		{
			path: '/documents',
			name: 'My Documents',
			element: <MyDocuments />,
			navMenu: 'full',
			childOf: '/my-account',
			loggedInRoutes: [
				'/my-account',
				'/how-to-apply',
				'/find-your-term',
				'/find-a-program',
				'/contact-us',
				'/sign-out'
			],
			requireAuthentication: true
		},
		{
			path: '/how-to-apply',
			name: 'How to Apply',
			element: <HowToApply />,
			navMenu: 'full',
			loggedInRoutes: [
				'/how-to-apply',
				'/find-your-term',
				'/find-a-program',
				'/my-account',
				'/contact-us',
				'/sign-out'
			],
			preAuthRoutes: ['/', '/direct-admit', '/how-to-apply', '/find-your-term', '/find-a-program', '/contact-us']
		},
		{
			path: '/find-your-term',
			name: 'Find Your Term',
			element: <FindYourTerm />,
			navMenu: 'full',
			loggedInRoutes: [
				'/how-to-apply',
				'/find-your-term',
				'/find-a-program',
				'/my-account',
				'/contact-us',
				'/sign-out'
			],
			preAuthRoutes: ['/', '/direct-admit', '/how-to-apply', '/find-your-term', '/find-a-program', '/contact-us'],
			dataDependencies: ['terms', 'campuses']
		},
		{
			path: '/find-a-program',
			name: 'Find a Program',
			element: <FindAProgram />,
			navMenu: 'full',
			loggedInRoutes: [
				'/how-to-apply',
				'/find-your-term',
				'/find-a-program',
				'/my-account',
				'/contact-us',
				'/sign-out'
			],
			preAuthRoutes: ['/', '/direct-admit', '/how-to-apply', '/find-your-term', '/find-a-program', '/contact-us'],
			dataDependencies: ['cdrMajors', 'campuses', 'career_clusters']
		},
		{
			path: '/save-and-return-later',
			name: 'Save & Return Later'
		},
		{
			path: '/contact-us',
			name: 'Contact Us',
			element: <ContactUs />,
			preAuthRoutes: [
				'/',
				'/sign-in',
				'/create-account',
				'/reset-password',
				'/how-to-apply',
				'/find-your-term',
				'/find-a-program',
				'/contact-us'
			]
		},
		{
			path: '/sign-out',
			name: 'Sign Out'
		}
	];

export default routes.map(route => {
	route.dataDependencies = route.dataDependencies || [];
	route.dataDependencies.push('timezone_diff');

	return route;
});
